/* eslint-disable react/react-in-jsx-scope */
import { TextInput, TextInputProps } from "@pomebile/primitives"
import { InputField, InputFieldProps } from "./InputField"

export interface TextFieldProps extends Omit<InputFieldProps, "inputComponent">, TextInputProps {}

export const TextField = ({
  label,
  value,
  type,
  disabled,
  readonly = false,
  placeholder,
  inputAdornment,
  inputAdornmentEnd,
  autoCapitalize,
  maxLength,
  onChange,
  supportingText,
  customWarningText,
  errorText,
  infoText,
  infoTextVariant,
  borderStyle,
  onBlur,
  multiline,
  numberOfLines,
}: TextFieldProps) => {
  return (
    <InputField
      inputComponent={
        <TextInput
          readonly={readonly}
          disabled={disabled}
          value={value}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          inputAdornment={inputAdornment}
          inputAdornmentEnd={inputAdornmentEnd}
          borderStyle={errorText ? "error" : borderStyle}
          onBlur={onBlur}
          autoCapitalize={autoCapitalize}
          maxLength={maxLength}
          multiline={multiline}
          numberOfLines={numberOfLines}
        />
      }
      label={label}
      customWarningText={customWarningText}
      supportingText={supportingText}
      errorText={errorText}
      infoText={infoText}
      infoTextVariant={infoTextVariant}
      borderStyle={borderStyle}
    />
  )
}
