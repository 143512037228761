/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * Pomelo Backend Server v1 API Documentation
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  ApiError,
  FlagConfiguration,
  OpenAccountRequest,
  ProductGroupResponse,
  ReviewRequestStatusResponse,
  SubscriptionStatusRequest,
  SubscriptionStatusResponse,
  UploadProfilePictureRequest,
  UserLockedResponse,
  UserResponse,
} from "../models"

export const getFeatureFlags = {
  url: "/v1/clientFlags",
  method: "GET",
  meta: {} as {
    res: FlagConfiguration
  },
} as const

export const getProfilePicture = {
  url: "/v1/getProfilePicture/{userIdent}/",
  method: "GET",
  meta: {} as {
    pathParams: {
      userIdent: /* IS NOT ENUM */ string
    }
    res: string
  },
} as const

export const getSubscriptionStatuses = {
  url: "/v1/subscriptionStatus",
  method: "GET",
  meta: {} as {
    res: SubscriptionStatusResponse
  },
} as const

export const getUser = {
  url: "/v1/getUser",
  method: "GET",
  meta: {} as {
    res: UserResponse
  },
} as const

export const openAccount = {
  url: "/v1/openAccount",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: OpenAccountRequest
    res: ProductGroupResponse
  },
} as const

export const setSubscriptionStatuses = {
  url: "/v1/subscriptionStatus",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: SubscriptionStatusRequest
    res: SubscriptionStatusResponse
  },
} as const

export const shouldRequestReview = {
  url: "/v1/reviewRequestStatus",
  method: "PUT",
  meta: {} as {
    queryParams: {
      operatingSystem?: string
    }
    res: ReviewRequestStatusResponse
  },
} as const

export const uploadProfilePicture = {
  url: "/v1/uploadProfilePicture",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    queryParams: {
      userIdent: string
    }
    req: UploadProfilePictureRequest
    res: UserResponse
  },
} as const

export const userDelinquentStatus = {
  url: "/v1/userDelinquentStatus",
  method: "GET",
  meta: {} as {
    res: UserLockedResponse
  },
} as const
