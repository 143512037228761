import { core, usAutocompletePro, usZipcode, usStreet } from "smartystreets-javascript-sdk"

const key = "93888005864925481"
const credentials = new core.SharedCredentials(key)

const usAutocompleteClientBuilder = new core.ClientBuilder(credentials).withLicenses([
  "us-autocomplete-pro-cloud",
])
const usZipcodeClientBuilder = new core.ClientBuilder(credentials)
const usStreetClientBuilder = new core.ClientBuilder(credentials)

export const usAutocompleteClient = usAutocompleteClientBuilder.buildUsAutocompleteProClient()
export const usZipcodeClient = usZipcodeClientBuilder.buildUsZipcodeClient()
export const usStreetClient = usStreetClientBuilder.buildUsStreetApiClient()

export const findAddress = async (address: string): Promise<usAutocompletePro.Lookup> => {
  const lookup = new usAutocompletePro.Lookup(address)
  lookup.maxResults = 8

  return usAutocompleteClient.send(lookup)
}

type VerifyAddressResponse =
  | { result: "ok"; address: usStreet.Candidate }
  | { result: "no_match" }
  | { result: "error"; message: string }

export const verifyAddress = async ({
  street,
  secondary,
  city,
  state,
  zipCode,
}: {
  street: string
  secondary?: string
  city: string
  state: string
  zipCode: string
}): Promise<VerifyAddressResponse> => {
  const lookup = new usStreet.Lookup()

  lookup.street = street
  if (secondary) {
    lookup.secondary = secondary
  }
  lookup.city = city
  lookup.state = state
  lookup.zipCode = zipCode

  try {
    const res = await usStreetClient.send(lookup)

    if (res.lookups.length && res.lookups[0].result.length) {
      return {
        result: "ok",
        address: res.lookups[0].result[0],
      }
    }

    return { result: "no_match" }
  } catch (err) {
    if (err instanceof Error) {
      return { result: "error", message: err.message }
    }
    return { result: "error", message: "Verify Address Error" }
  }
}

export const validateZipAgainstState = async (zipCode: string, state: string): Promise<boolean> => {
  // 1. Create zip code lookup object
  const city = undefined
  const lookup = new usZipcode.Lookup(city, state, zipCode)

  // 2. Fetch addresses using lookup
  let response: core.Batch<usZipcode.Lookup>
  try {
    response = await usZipcodeClient.send(lookup)
  } catch (err) {
    // in case of network or other issues (such as auth) assume that the zip and states are valid
    // TODO rework
    return true
  }

  // 3a. Terminate early if no results were found
  if (!response.lookups.length) {
    return false
  }

  // 3b. Because we're using SmartyStreets' zipcode client, it is biased towards fetching addresses that have the input zipCode (i.e. returns address with same zip code but different state).
  // To further validate the response, we should go through all the resulting addresses and find at least a single one with the same zip code and state as our inputs
  const isValid = !!response.lookups.find((lookupRes) =>
    lookupRes.result.find(
      (address) =>
        address.valid &&
        address.zipcodes.find(
          (zipcode) => zipcode.zipcode === zipCode && zipcode.stateAbbreviation === state,
        ),
    ),
  )

  return isValid
}
