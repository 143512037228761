// Color palette

export const colorPalette = {
  black: "#000000",
  "black-opacity-70": "rgba(0, 0, 0, 0.7)",
  white: "#FFFFFF",
  "white-opacity-90": "rgba(255, 255, 255, 0.9)",

  "pink-100": "#F04679",
  "pink-40": "#FAC7D6",
  "pink-10": "#FEEDF2",
  "pink-5": "#FEF6F8",

  "green-100": "#00B198",
  "green-40": "#CCEFEA",
  "green-10": "#E6F8F5",
  "green-5": "#F2FBFA",

  "gray-3": "#F7F7F7",
  "gray-5": "#F2F2F2",
  "gray-10": "#E6E6E6",
  "gray-20": "#D2D2D2",
  "gray-40": "#999999",
  "gray-60": "#666666",
  "gray-80": "#333333",
  "gray-100": "#1D1D1D",

  "red-100": "#F04646",
  "red-40": "#F7A2A2",
  "red-10": "#FEEDED",

  "yellow-100": "#FFDE0A",
  "yellow-60": "#FFEE84",
  "yellow-10": "#FFF7C2",

  "mastercard-orange": "#F26522",
  "mastercard-red": "#E52423",
  "mastercard-yellow": "#F99F1C",
} as const

export type PaletteColors = keyof typeof colorPalette
export type PaletteColorsValue = (typeof colorPalette)[PaletteColors]

/** Extract border colors for improved sprinkle consumption  */
export const borderColors = {
  "border-main": colorPalette["gray-10"],
  "border-input-default": colorPalette["gray-20"],
  "border-input-focused": colorPalette["gray-100"],
  "border-input-error": colorPalette["red-100"],
  "border-input-disabled": colorPalette["gray-3"],
  "border-button-primary": colorPalette["pink-100"],
  "border-button-loading": colorPalette["pink-40"],
  "border-button-disabled": colorPalette["gray-20"],
  "border-checkbox-active": colorPalette["pink-100"],
  "border-checkbox-default": colorPalette["gray-40"],
} as const
export type BorderColors = keyof typeof borderColors
export type BorderColorValues = (typeof borderColors)[BorderColors]

export const iconColors = {
  "icon-disabled": colorPalette["gray-10"],
  "icon-secondary": colorPalette["gray-40"],
  "icon-default": colorPalette["gray-60"],
  "icon-emphasis": colorPalette["gray-100"],
  "icon-error": colorPalette["red-100"],
  "icon-inverse": colorPalette.white, // used for card
  "icon-brand": colorPalette["pink-100"],
  "icon-success": colorPalette["green-100"],
  "icon-mastercard-orange": colorPalette["mastercard-orange"],
  "icon-mastercard-red": colorPalette["mastercard-red"],
  "icon-mastercard-yellow": colorPalette["mastercard-yellow"],
} as const

export type IconColors = keyof typeof iconColors

export const pomeloCardColors = {
  none: "none",
  pink: colorPalette["pink-100"],
  green: colorPalette["green-100"],
  white: colorPalette.white,
} as const

export type CardColors = keyof typeof pomeloCardColors
export type PomeloCardColorValues = (typeof pomeloCardColors)[CardColors]

export const backgroundColors = {
  "background-default": colorPalette.white,
  "background-black": colorPalette["gray-80"],
  "background-avatar-primary": colorPalette["pink-100"],
  "background-avatar-default": colorPalette["gray-5"],
  "background-avatar-error": colorPalette["red-10"],
  "background-avatar-action": colorPalette["pink-5"],
  "background-avatar-success": colorPalette["green-10"],

  "background-text-input-disabled": colorPalette["gray-3"],

  "background-dropdown-focused": colorPalette["gray-5"],

  "background-combo-box-hover": colorPalette["gray-5"],

  "background-checkbox-active": colorPalette["pink-100"],

  "background-button-disabled": colorPalette["gray-20"],
  "background-button-primary": colorPalette["pink-100"],
  "background-button-secondary": colorPalette["green-100"],
  "background-button-loading": colorPalette["pink-10"],
  "background-button-outline": "transparent",

  "background-callout-default": colorPalette["gray-10"],
  "background-callout-error": colorPalette["red-10"],
  "background-callout-warning": colorPalette["yellow-10"],
  "background-callout-success": colorPalette["green-10"],
  "background-callout-success-lite": colorPalette["green-5"],
  "background-callout-primary": colorPalette["pink-10"],

  "background-dialog-overlay": colorPalette["black-opacity-70"],

  "background-bordered-callout-default": colorPalette["gray-5"], // Used in billing address component

  "background-sticky-bottom-default": colorPalette["white-opacity-90"],
} as const

export type BackgroundColors = keyof typeof backgroundColors
export type BackgroundColorValues = (typeof backgroundColors)[BackgroundColors]

export const txtColors = {
  "text-default": colorPalette["gray-100"],
  "text-caption": colorPalette["gray-60"],
  "text-disabled": colorPalette["gray-40"],
  "text-placeholder": colorPalette["gray-40"],
  "text-error": colorPalette["red-100"],
  "text-inverse": colorPalette.white,
  "text-emphasis": colorPalette["green-100"],
  "text-brand": colorPalette["pink-100"],
  "text-link-active": colorPalette["pink-100"],

  "text-button-filled": colorPalette.white,
  "text-button-outlined": colorPalette["pink-100"],

  // Unused
  "text-link-disabled": colorPalette["gray-20"],
}

export type TxtColors = keyof typeof txtColors
