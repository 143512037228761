export const validateSsnOrItin = (ssnOrItin: string): boolean => {
  return isValidSsn(ssnOrItin) || isValidItin(ssnOrItin)
}

const isCorrectFormat = (value: string) => /^\d{3}-\d{2}-\d{4}$/.test(value)

/*
  Validate SSNs against the following rules:
  - The format must be ddd-dd-dddd (including dashes)
  - All characters must be digits
  - The first group of digits cannot be 000 or 666
  - No group of digits can be all zeros
  - The SSN can't be 123-45-6789
*/
export const isValidSsn = (ssn: string) => {
  if (!isCorrectFormat(ssn)) {
    return false
  }

  const ssnParts = ssn.split("-")
  const [firstGroup, middleGroup, lastGroup] = ssnParts

  if (firstGroup[0] === "9") {
    return false
  }

  if (firstGroup === "000" || middleGroup === "00" || lastGroup === "0000") {
    // None of the three groups may be all zeros
    return false
  }

  if (firstGroup === "666") {
    // The first group may not be 666
    return false
  }

  if (firstGroup === "123" && middleGroup === "45" && lastGroup === "6789") {
    // SSN may not be 123-45-6789
    return false
  }

  return true
}

/*
  Validate ITINs against the following rules:
  - The format must be ddd-dd-dddd (including dashes)
  - All characters must be digits
  - The first digit must be 9
*/
const isValidItin = (itin: string) => {
  if (!isCorrectFormat(itin)) {
    return false
  }

  const itinParts = itin.split("-")
  const [firstGroup, middleGroup, lastGroup] = itinParts
  const middleGroupNumber = +middleGroup

  if (firstGroup[0] !== "9") {
    return false
  }

  if (middleGroupNumber < 50) {
    return false
  }
  if (middleGroupNumber > 65 && middleGroupNumber < 70) {
    return false
  }
  if (middleGroupNumber === 89) {
    return false
  }
  if (middleGroupNumber === 93) {
    return false
  }

  return true
}
