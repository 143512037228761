/**
 * Converts "somethingLikeThis" to "Something Like This", but "OTP" stays the same.
 * Separates words and acronyms, e.g. "AtAtATAt" becomes "At At AT At"
 */
export const toTitleCase = (camelCaseString: string) => {
  const spacedRoute = camelCaseString
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z]+)([A-Z][a-z])/g, "$1 $2")
  const titleCasedRoute = spacedRoute.charAt(0).toUpperCase() + spacedRoute.slice(1)
  return titleCasedRoute.trim()
}

export const extractDigits = (str: string) => str.replace(/\D/g, "")

export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const toCamelCase = (snakeCaseString: string) => {
  const parts = snakeCaseString.toLowerCase().split("_")

  return parts.map((part) => part.charAt(0).toUpperCase() + part.slice(1)).join("")
}

export const truncateString = (input: string, maxLength: number): string => {
  if (input.length <= maxLength) {
    return input
  }
  return input.slice(0, maxLength).trimEnd() + "..."
}
