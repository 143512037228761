import { LockIcon, TextField } from "@pomebile/design-system"
import { Button, HStack, Secure, Txt, VStack } from "@pomebile/primitives"
import * as Yup from "yup"
import * as V from "../utils/formValidation/validationMessages"
import { ScreenForm } from "../components/ScreenForm"
import { StickyBottom } from "../components/StickyBottom"
import { FormSubmitButton } from "../components/Form/FormSubmitButton"
import { useForm } from "../components/Form/useForm"
import { useRef, useState } from "react"
import { validateDateOfBirth } from "../utils/formValidation/validators"
import { validateSsnOrItin } from "../utils/ssn"
import { VerifyIdentityProps } from "./VerifyIdentity.tsx"

export const IdentitySsnOrItinSchema = Yup.object().shape({
  dateOfBirth: validateDateOfBirth({ minAge: 18 }), // Placed at the end to show this error first
  ssn: Yup.string()
    .test("is-valid-ssn-itin", V.MSG_SSN_ITIN, (value) => !!value && validateSsnOrItin(value))
    .max(11, V.MSG_SSN_ITIN)
    .min(11, V.MSG_SSN_ITIN)
    .required(V.MSG_REQUIRED),
})

const extractSsnDigits = (ssn: string) => ssn.replace(/\D/g, "")

const formatSsn = (ssnDigits: string) => {
  const firstPart = ssnDigits.slice(0, 3)
  const middlePart = ssnDigits.slice(3, 5)
  const lastPart = ssnDigits.slice(5)

  if (lastPart.length > 0) {
    return `${firstPart}-${middlePart}-${lastPart}`
  }

  if (middlePart.length === 2) {
    return `${firstPart}-${middlePart}-`
  }

  if (firstPart.length === 3) {
    if (middlePart.length > 0) {
      return `${firstPart}-${middlePart}`
    }

    return `${firstPart}-`
  }

  return firstPart
}

const getHiddenSsn = (ssn: string) => {
  return ssn.replace(/\d/g, "•")
}

export function VerifyIdentitySsnOrItin({ api, onDone }: VerifyIdentityProps) {
  const prevDateOfBirthValue = useRef("")
  const [rawSsn, setRawSsn] = useState<string>("")
  const [formattedSsn, setFormattedSsn] = useState<string>("")
  const [formattedHiddenSsn, setFormattedHiddenSsn] = useState<string>("")
  const [isSsnHidden, setIsSsnHidden] = useState<boolean>(true)

  const [submit, getFieldProps, status, { isFormValid }] = useForm({
    name: "Personal Details", // Note: Must match 1.0 name for analytics
    schema: IdentitySsnOrItinSchema,
    initial: { dateOfBirth: "", ssn: "" },
    submit: async ({ dateOfBirth, ssn }) => {
      let dateOfBirthParts: number[]

      if (dateOfBirth.includes("-")) {
        // follows the format [yyyy, mm, dd]
        dateOfBirthParts = dateOfBirth.split("-").map((numberStr) => Number(numberStr))
      } else {
        // follows the format [mm, dd, yyyy]
        const [mm, dd, yyyy] = dateOfBirth.split("/").map((numberStr) => Number(numberStr))
        // reorder to follow [yyyy, mm, dd] format
        dateOfBirthParts = [yyyy, mm, dd]
      }

      await api.submitDateOfBirth(dateOfBirthParts as [number, number, number])
      const ssnDigits = extractSsnDigits(ssn)
      const identifierType = ssnDigits[0] !== "9" ? "SSN" : "ITIN"
      const res = await api.submitSsnOrItin(ssnDigits, identifierType)

      onDone(res)
    },
  })

  const { onChange: onChangeSsn, ...restOfSsnProps } = getFieldProps("ssn")
  const { onChange: onChangeDateOfBirth, ...restOfDateOfBirthProps } = getFieldProps("dateOfBirth")

  const handleSsnChange = (value: string) => {
    const prevFormattedSsn = formattedSsn
    let newRawSsn: string = rawSsn
    if (value.length > prevFormattedSsn.length) {
      // typing forward
      const newChar = value.split("").pop()

      // only add the new character if it's a digit
      if (!isNaN(Number(newChar))) {
        newRawSsn = `${rawSsn}${newChar}`
      }
    } else {
      // backspacing / deleting
      const charsRemovedCount = prevFormattedSsn.length - value.length
      newRawSsn = rawSsn.slice(0, rawSsn.length - charsRemovedCount)
    }

    setRawSsn(newRawSsn)

    const newFormattedSsn = formatSsn(newRawSsn)
    setFormattedSsn(newFormattedSsn)
    setFormattedHiddenSsn(getHiddenSsn(newFormattedSsn))
    onChangeSsn(newFormattedSsn)
  }

  const handleDateOfBirthChange = (value: string) => {
    prevDateOfBirthValue.current = restOfDateOfBirthProps.value

    if (value === "") onChangeDateOfBirth("")

    // Regex that covers all possible formating combinations while users type in their date of birth
    const datePattern =
      /^(0|1|0[1-9]|1[0-2])(\/(0|1|2|3|0[1-9]|[12][0-9]|3[01])?(\/(1|2|19|20|\d{3}|\d{4})?)?)?$/

    let inputDate = value

    if (!datePattern.test(inputDate)) {
      return
    }

    const isExpectedLength = inputDate.length === 2 || inputDate.length === 5 // expects the formats MM and MM/DD
    const userTypedInCharacter = prevDateOfBirthValue.current.length <= inputDate.length // user typed in a new character

    // Auto-format adding a slash in between month, day, and year
    if (isExpectedLength && userTypedInCharacter) {
      inputDate = `${inputDate}/`
    }

    onChangeDateOfBirth(inputDate)
  }

  return (
    <ScreenForm onSubmit={submit}>
      <VStack gap="xl2">
        <VStack gap="sm">
          <Txt variant="headline2" as="h2">
            Verify your identity
          </Txt>
          <Txt>
            We are required to collect this information to verify your identity. Please ensure all
            details are accurate to avoid delays.
          </Txt>
        </VStack>
        <VStack gap="xs">
          <Secure>
            <TextField
              label="Date of birth"
              placeholder="MM/DD/YYYY"
              type="text"
              onChange={handleDateOfBirthChange}
              {...restOfDateOfBirthProps}
            />
            <div style={{ position: "relative" }}>
              <TextField
                label="SSN or ITIN"
                placeholder="XXX-XX-XXXX"
                type="text"
                {...restOfSsnProps}
                value={isSsnHidden ? formattedHiddenSsn : formattedSsn}
                onChange={handleSsnChange}
                maxLength={11}
              />
              <div style={{ position: "absolute", right: 0, bottom: 22 }}>
                <Button
                  variant="text"
                  web-type="button"
                  onClick={() => setIsSsnHidden(!isSsnHidden)}
                >
                  {isSsnHidden ? "Show" : "Hide"}
                </Button>
              </div>
            </div>
          </Secure>
          <HStack
            alignItems="center"
            fill="background-avatar-default"
            borderRadius="md"
            padding={{ x: "lg", y: "md" }}
            gap="sm"
          >
            <VStack justifyContent="center" flex={1}>
              <LockIcon width={24} height={24} fill="icon-emphasis" />
            </VStack>
            <VStack>
              <Txt variant="body2">
                Your SSN or ITIN is encrypted using banking-grade technology.
              </Txt>
            </VStack>
          </HStack>
        </VStack>
      </VStack>
      <StickyBottom>
        <FormSubmitButton disabled={!isFormValid} status={status}>
          Continue
        </FormSubmitButton>
      </StickyBottom>
    </ScreenForm>
  )
}
