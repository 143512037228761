import { HStack, Txt } from "@pomebile/primitives"
import { ExclamationIcon } from "../icons/exclamation.icon"

export interface TextFieldErrorTextProps {
  error: string | undefined
}

export const TextFieldErrorText = ({ error }: TextFieldErrorTextProps) => {
  return (
    <HStack gap="xs2" alignItems="center">
      <ExclamationIcon width={16} height={16} fill="icon-error" />
      <Txt as="span" variant="caption" color="text-error">
        {error}
      </Txt>
    </HStack>
  )
}
