import {
  matchDeferred,
  matchDeferredTuple,
  taggedUnion,
  Variants,
} from "@pomebile/shared/tagged-union"
import { getCurrencyFormat } from "./utils/money"
import { RewardVoucher } from "./api/webRoutes"
import { ClaimRewardProps } from "./screens/ClaimReward"

interface Screens {
  FetchingRewardVoucher: {
    rewardUniqueId: string
  }
  ClaimRewardScreen: ClaimRewardProps
  GeneralError: void
}

export const AppScreen = taggedUnion<Screens>()
export type AppScreen = Variants<Screens>

interface AppStates {
  FetchingRewardVoucher: {
    rewardUniqueId: string
  }
  ClaimingReward: {
    rewardUniqueId: string
    rewardVoucher: RewardVoucher
  }
  GeneralError: void
}

interface AppEvents {
  FetchedRewardVoucher: {
    rewardVoucher: RewardVoucher
  }
  ClaimedReward: void
  EncounteredGeneralError: { errorType: string }
}

export const AppState = taggedUnion<AppStates>()
export type AppState = Variants<AppStates>

export const AppEvent = taggedUnion<AppEvents>()
export type AppEvent = Variants<AppEvents>

export const calculateRewardScreen: (state: AppState) => AppScreen = matchDeferred(AppState, {
  FetchingRewardVoucher: (prev) => AppScreen.FetchingRewardVoucher({ ...prev }),
  ClaimingReward: ({ rewardVoucher, rewardUniqueId }) => {
    const currencyFormatter = getCurrencyFormat(rewardVoucher.currencyCode)
    const description = `${currencyFormatter.format(rewardVoucher.giftValue)} promo code`

    return AppScreen.ClaimRewardScreen({
      rewardUniqueId,
      description,
      rewardType: rewardVoucher.rewardType,
      senderName: rewardVoucher.senderName,
      voucherCode: rewardVoucher.voucherCode,
      expirationDate: rewardVoucher.expirationDate,
    })
  },
  GeneralError: () => AppScreen.GeneralError(),
})

const { ClaimingReward, GeneralError } = AppState

export const updateRewardAppState = matchDeferredTuple(AppState, AppEvent, {
  FetchingRewardVoucher: {
    FetchedRewardVoucher: ({ rewardUniqueId }, { rewardVoucher }) => {
      return ClaimingReward({ rewardVoucher, rewardUniqueId })
    },
    EncounteredGeneralError: () => GeneralError(),
  },
  default: (prev) => prev,
})
